import React, { useEffect } from 'react';

function ScreenLock() {
    const tabIdleThreshold = 15 * 60 * 1000; // time seconds in milliseconds

    let tabIdleTimer;

    const resetTabIdleTimer = () => {
        clearTimeout(tabIdleTimer);
        tabIdleTimer = setTimeout(lockTab, tabIdleThreshold);
    };

    const lockTab = () => {
        let isAuth = localStorage.getItem("jobman-token");
        if(isAuth){
            let isAdmin = localStorage.getItem("jobman-admin");
            localStorage.removeItem("jobman-admin");
            localStorage.removeItem("jobman-token");
            window.location.href = isAdmin ? '/admin/login' : '/login';
        }
    };

    useEffect(() => {
        // Set up event listeners to track user activity within this tab
        window.addEventListener('mousemove', resetTabIdleTimer);
        window.addEventListener('keydown', resetTabIdleTimer);
        window.addEventListener('click', resetTabIdleTimer);

        // Initialize the tab idle timer
        resetTabIdleTimer();

        // Clean up event listeners when the component unmounts
        return () => {
            window.removeEventListener('mousemove', resetTabIdleTimer);
            window.removeEventListener('keydown', resetTabIdleTimer);
            window.removeEventListener('click', resetTabIdleTimer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (<></>);
}

export default ScreenLock;