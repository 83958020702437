import _ from "lodash";
import $ from 'jquery';
import { authloader } from "./Loader";

const API_URL = process.env.REACT_APP_API_URL

export const showFromErrors = (errors, form = '') => {
    let i = 1;
    for (let key in errors) {
        if (i === 1) {
            $(`input[name="${key}"]`).closest('form').find('.invalid-custom-feedback').remove();
            $(`input[name="${key}"]`).closest('form').removeClass('was-validated');

            $(`textarea[name="${key}"]`).closest('form').find('.invalid-custom-feedback').remove();
            $(`textarea[name="${key}"]`).closest('form').removeClass('was-validated');
        }

        $(`${form} input[name="${key}"]`).addClass('is-invalid');
        $(`${form} input[name="${key}"]`).after(`<div class="invalid-feedback invalid-custom-feedback d-block manage_avtart">${errors[key][0]}</div>`)

        $(`${form} textarea[name="${key}"]`).addClass('is-invalid');
        $(`${form} textarea[name="${key}"]`).after(`<div class="invalid-feedback invalid-custom-feedback d-block manage_avtart">${errors[key][0]}</div>`)
        
        i++;
    }
}

export const showAlert = (data) => {
    let idGenerate = _.floor((_.random(0, 9) * 1000000) + 1)
    let alertType = (data.status === true) ? 'success' : 'danger'

    const htmlToast = `<div id="${idGenerate}" class="toast fade bg-white mb-2 border-0" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="4000">
                                <div class="d-flex rounded bg-${alertType}">
                                    <div class="toast-body fw-medium text-white me-auto">${data.message}</div>
                                    <button type="button" class=" btn-close ms-2 me-2 mt-2 min-wh-20px" data-bs-dismiss="toast" aria-label="Close"></button>
                                </div>
                            </div>`;

    $('#toastContainer').html(htmlToast)

    let getIdToast = document.getElementById(idGenerate);
    let toast = new window.bootstrap.Toast(getIdToast);
    toast.show();
    getIdToast.addEventListener('hidden.bs.toast', () => setTimeout(() => this.remove(), 700000));
}

export const validateForm = (e, formId = '') => {
    e.preventDefault()
    let selector = formId === '' ? '.needs-validation' : `#${formId}.needs-validation`;
    let error = 0;

    Array.prototype.slice.call(document.querySelectorAll(selector))
        .forEach(function (form) {
            if (!form.checkValidity()) {
                error++;
                e.preventDefault();
                e.stopPropagation();
            }
            form.classList.add('was-validated')
        })

    let react_select_error = 0;
    document.querySelectorAll(`.react-select`).forEach(ele => {
        ele.classList.remove('is-invalid')
        ele.classList.add('is-valid')
    })

    let react_select_selector = formId === '' ? `.react-select input[required]` : `#${formId} .react-select input[required]`;
    document.querySelectorAll(react_select_selector).forEach(ele => {
        if (ele.nextElementSibling && ele.nextElementSibling.classList.contains('invalid-feedback')) {
            ele.nextElementSibling.remove()
        }
        ele.parentElement.classList.remove('is-valid')
        ele.parentElement.classList.add('is-invalid')
        $(`${selector} input[name="${ele.name}"]`).after(`<div class="invalid-feedback">This Field is required.</div>`)
        react_select_error++;
    })

    document.querySelectorAll(`${selector} .react-datepicker__input-container input[required]`).forEach(ele => {
        console.log(ele, ele.value, ele.name);
        if (ele.value.trim() === '') {
            ele.classList.remove('is-valid')
            ele.classList.add('is-invalid')
            if (ele.nextElementSibling && ele.nextElementSibling.classList.contains('invalid-feedback')) {
                ele.nextElementSibling.remove()
            }
            $(`${selector} .react-datepicker__input-container input[name="${ele.name}"]`).after(`<div class="invalid-feedback">This Field is required.</div>`)
            error++;
        }
    })
    

    if (error || react_select_error) return false
    else return true
}

export const getData = async (url, token, res, abort_signal = false, loading = false, admin = false) => {
    let headers = {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    }

    if (token) {
        let TOKEN = localStorage.getItem('jobman-token')
        if(admin){
            headers = { ...headers, 'Authorization': TOKEN }
        }else{
            headers = { ...headers, 'Authorization': 'Bearer ' + TOKEN }
        }
    }

    let request = { 'method': 'GET', 'headers': headers }
    if (abort_signal) request = { ...request, 'signal': abort_signal }
    if (loading) authloader(true)

    await fetch(`${API_URL}${url}`, request).then((response) => {
        return response.json()
    }).then((json) => {
        if (json.message === "Unauthenticated.") {
            localStorage.removeItem("jobman-token");
            window.location.href = '/'
        } else {
            let message = json.message ? json.message : (json.success ? json.success : json.error)
            if (message) showAlert({ status: (json.success || json.message) ? true : false, message: message });
            res(json)
        }
        if (loading) authloader(false)
    }).catch((error) => { console.log(error) });
}

export const postData = async (url, method, data, token, process, res, abort_signal = false, process_type = false, loading = true, form_id = '', alert = true, admin = false) => {
    if (loading) loadingData(true)
    let headers = {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
    }

    if (process) {
        headers = { ...headers, 'contentType': false, 'processData': false }
    } else {
        headers = { ...headers, 'Content-Type': 'application/json' }
    }

    if (token) {
        let TOKEN = localStorage.getItem('jobman-token')
        if(admin){
            headers = { ...headers, 'Authorization': TOKEN }
        }else{
            headers = { ...headers, 'Authorization': 'Bearer ' + TOKEN }
        }
    }

    let request = {
        'method': method,
        'headers': headers,
    }

    if (abort_signal) {
        request = { ...request, 'signal': abort_signal }
    }

    if (data) {
        request = { ...request, 'body': process ? data : JSON.stringify(data) }
    }
    await fetch(`${API_URL}${url}`, request).then((response) => {
        if (response.status === 404) {
            window.location.href = '/404'
        } else {
            return process_type === "text" ? response.text() : (process_type === "blob" ? response.blob() : response.json())
        }
    }).then((json) => {
        if(admin && json.success == 'Unauthorized'){
            localStorage.removeItem("jobman-admin");
            localStorage.removeItem("jobman-token");
            window.location.href = '/'
        }
        if (loading) loadingData()
        if (json.message === "Unauthenticated.") {
            localStorage.removeItem("jobman-token");
            window.location.href = '/'
        } else {
            if (alert) {
                let message = json.message ? json.message : (json.success ? json.success : json.error)
                if (message) showAlert({ status: (json.success || json.message) ? true : false, message: message });
                if (json.errors) showFromErrors(json.errors, form_id)
            }
            res(json)
        }
    }).catch((error) => { console.log(error) });
}

export const reactSelectValidation = (fields) => {
    let error = false;
    fields.forEach(element => {
        if (element.value.length) {
            document.getElementById(element.id).classList.add("is-valid");
            document.getElementById(element.id).classList.remove("is-invalid");
        } else {
            document.getElementById(element.id).classList.add("is-invalid");
            document.getElementById(element.id).classList.remove("is-valid");
            error = true;
        }
    });
    return !error
}

export const initialFormState = (formId, reset = true) => {
    [...document.querySelectorAll(`#${formId} .form-control, #${formId} .form-select, #${formId} .invalid-feedback`)].forEach((elm) => {
        elm.classList.remove('is-invalid');
        elm.classList.remove('is-valid');
        elm.classList.remove('d-block');
    });
    document.getElementById(formId).classList.remove('was-validated')
    if (reset) document.getElementById(formId).reset()
}



export const scrollbarSetToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'auto' });
}

export const loadingData = (active) => {
    if (active) document.querySelector('body').classList.add('loading-data');
    else document.querySelector('body').classList.remove('loading-data');
}

export const loader = (active) => {
    if (active) document.getElementById('preloader').classList.remove('loaded');
    else document.getElementById('preloader').classList.add('loaded');
}

export const loaderASec = () => {
    document.getElementById('preloader').classList.remove('loaded');
    setTimeout(() => {
        document.getElementById('preloader').classList.add('loaded');
    }, 1000);
}

export const noImageHandle = (e, img = null) => {
    e.target.src = img ?? "/images/no-image.png";
}

export const srcToBase64 = async (url, res) => {
    await fetch(`https://cors-fix.web.app/v1?url=${url}`)
        .then(data => data.blob().then(blob => {
            const reader = new FileReader();
            reader.onloadend = () => {
                res(reader.result)
            };
            reader.readAsDataURL(blob);
        }));
}

export const downloadBase64File = (url, name = 'attachment') => {
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
}
