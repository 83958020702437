import { lazy } from 'react';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const MetaData = {
    dashboard: { title: "Dashboard", description: "", banner_img: null },
    my_profile: { title: "My Profile", description: "", banner_img: null },
    change_password: { title: "Change Password", description: "", banner_img: null },
    lead_list: { title: "Lead List", description: "", banner_img: null },
    test: { title: "Test", description: "", banner_img: null, breadcrumbs: <> <li><Link to="/">Parent </Link></li><li><NavigateNextIcon /></li></> },
    table: { title: "Table", description: "", banner_img: null },
    lead_view: { title: "Lead View", description: "", banner_img: null, breadcrumbs: <> <li><Link to="/my-account/lead-list">My Lead </Link></li><li><NavigateNextIcon /></li></> },
    quote_list: { title: "Quote List", description: "", banner_img: null },
}

const Dashboard = lazy(() => import('../modules/BackEnd/Dashboard'));
const Test = lazy(() => import('../modules/BackEnd/Test'));
const LeadList = lazy(() => import('../modules/BackEnd/LeadList'));
const ChangePassword = lazy(() => import('../modules/Auth/ChangePassword'));
const MyProfile = lazy(() => import('../modules/Auth/MyProfile'));
const Table = lazy(() => import('../modules/Test/Table'));
const TableTopBar = lazy(() => import('../modules/Test/TableTopBar'));
const Leadview = lazy(() => import('../modules/BackEnd/Leadview'));
const QuoteList = lazy(() => import('../modules/BackEnd/Partials/QuoteList'));

export const ProtectedRoute = [
    { path: "", component: <Dashboard />, metadata: MetaData.dashboard },
    { path: "dashboard", component: <Dashboard />, metadata: MetaData.dashboard },
    { path: "my-profile", component: <MyProfile />, metadata: MetaData.my_profile },
    { path: "change-password", component: <ChangePassword />, metadata: MetaData.change_password },
    { path: "lead-list", component: <LeadList />, metadata: MetaData.lead_list },
    { path: "test", component: <Test />, metadata: MetaData.test },
    { path: "table", component: <Table />, metadata: MetaData.table },
    { path: "table-top-bar", component: <TableTopBar />, metadata: MetaData.table },
    { path: "lead-view", component: <Leadview />, metadata: MetaData.lead_view },
    { path: "quote-list", component: <QuoteList />, metadata: MetaData.quote_list },
    
];