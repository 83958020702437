import { configureStore } from '@reduxjs/toolkit'
import counterSlice from './Slice/counterSlice'
import { pokemonApi } from './services/pokemon'
import { setupListeners } from '@reduxjs/toolkit/query'

export const store = configureStore({
    reducer: {
        counter: counterSlice,
        [pokemonApi.reducerPath]: pokemonApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(pokemonApi.middleware),
})

setupListeners(store.dispatch)