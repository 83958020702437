import { Link, useLocation } from "react-router-dom"

const Footer = () => {
    const location = useLocation()

    const checkLocation = (url, base = false) => {
        let match = url.some((l) => location.pathname.match(l));
        if (base && !match) {
            match = base.some((l) => location.pathname === l);
        }
        return match;
    }

    return (<>
        <div className="section footer-section">
            <div className="footer-widget-section text-center text-md-start">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-3">
                            <div className="footer-widget">
                                <div className="footer-logo">
                                    <Link to="/">
                                        <img src="/assets/images/footer-logo.png" width="154" height="46" alt="Logo" />
                                    </Link>
                                </div>
                                <div className="widget-about mt-3">
                                    <p>
                                        Joinery By Design specialises in the project management, fabrication and installation of complete commercial, residential and retail fit outs. Request a quote to get us involved in your next project!
                                    </p>
                                </div>
                                <div className="footer-social-links mt-3" hidden>
                                    <a className="footer-social-link fs-f" target="_blank" href="/"><i className="fa fa-facebook-f"></i></a>
                                    <a className="footer-social-link fs-t" target="_blank" href="/"><i className="fa fa-twitter"></i></a>
                                    <a className="footer-social-link fs-i" target="_blank" href="/"><i className="fa fa-instagram"></i></a>
                                    <a className="footer-social-link fs-y" target="_blank" href="/"><i className="fa fa-youtube-play"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 ps-lg-5">
                            <div className="footer-widget">
                                <h4 className="footer-widget-title">QUICK LINKS</h4>
                                <ul className="footer-link">
                                    <li className={checkLocation(['home'], ['/']) ? 'active pe-none' : ''}>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className={checkLocation(['about-us']) ? 'active pe-none' : ''}>
                                        <Link to="/about-us">About Us</Link>
                                    </li>
                                    <li className={checkLocation(['services']) ? 'active pe-none' : ''}>
                                        <Link to="/services">Services</Link>
                                    </li>
                                    <li className={checkLocation(['projects']) ? 'active pe-none' : ''}>
                                        <Link to="/projects">Projects</Link>
                                    </li>
                                    <li className={checkLocation(['steel-by-design']) ? 'active pe-none' : ''}>
                                        <Link to="/steel-by-design">Steel By Design</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="footer-widget">
                                <h4 className="footer-widget-title">OUR SERVICES</h4>
                                <p><a href="/services#design" className="m-0">Joinery Manufacturing</a></p>
                                <p> <a href="/services#fabrication" className="m-0">Steel Fabrication</a></p>
                                <p> <a href="/services#design-concepts" className="m-0">Design Concepts</a> </p>
                                <p><a href="/services#building-solutions" className="m-0">Commercial and Retail Building Solutions</a></p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="footer-widget">
                                <h4 className="footer-widget-title">CONTACT US</h4>
                                <p className="m-0">Please forward all enquiries to:</p>
                                <p className="m-0"><a style={{ color: "#aa8f6a" }} href="mailto:info@joinerybydesign.com.au">info@joinerybydesign.com.au</a></p>
                                <p className="m-0">Call for a Quote!</p>
                                <p className="m-0" style={{ color: "#aa8f6a" }}>1300 113 JBD</p>
                                <p className="m-0">Servicing all areas of Australia.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <div className="container">
                    <div className="copyright-text">
                        <p>Copyright © 2023 – Joinery By Design Pty. Ltd.  All rights reserved</p>
                    </div>
                </div>
            </div>
        </div>
        <button id="backBtn" className="back-to-top"><i className="pe-7s-angle-up"></i></button>
    </>)
}

export default Footer