import { createBrowserRouter, createRoutesFromElements, redirect, Route } from "react-router-dom";
import Root, { Layout, AuthLayout, AdminLayout } from "../layouts/Layout";
import ManagePage from "./ManagePage";
import { Suspense } from "react";
import { GET_ADMIN_USER, GET_AUTH_USER } from "./ApiRoutes";
import { NormalRoute } from "./NormalRoute";
import { ProtectedRoute } from "./ProtectedRoute";
import { loaderASec } from "./Helper";
import { authloader } from "./Loader";
import { AdminRoute } from "./AdminRoute";

const API_URL = process.env.REACT_APP_API_URL

function ErrorBoundary() {
    authloader();
    return <div className="text-center text-danger">Opps Something went wrong.</div>;
}

export const router = createBrowserRouter(
    createRoutesFromElements(<>
        <Route path="/" loader={async ({ request }) => {
            let token = localStorage.getItem('jobman-token')
            let admin = localStorage.getItem('jobman-admin')
            if (token && !admin) {
                let headers = {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
                const res = await fetch(`${API_URL}${GET_AUTH_USER}`, { headers: headers, signal: request.signal });
                const user = await res.json();
                loaderASec();
                return user.records ?? user;
            }
            setTimeout(() => loaderASec(), 100);
            return null;
        }} element={<Root />}>

            <Route path="/" caseSensitive={false} element={<Layout />}>
                {NormalRoute.map(route => {
                    return <Route
                        key={route.path}
                        path={route.path}
                        caseSensitive={false}
                        loader={() => {
                            if (localStorage.getItem('jobman-admin')) {
                                return redirect("/admin/login")
                            }
                            if (route.metadata.auth && !localStorage.getItem('jobman-token')) {
                                return redirect("/login")
                            }
                            return route.metadata
                        }}
                        errorElement={<ErrorBoundary />}
                        element={<Suspense fallback={<ManagePage nobanner />}>{route.component}</Suspense>}
                    />
                })}
            </Route>

            <Route path="my-account" loader={() => localStorage.getItem('jobman-token') ? null : redirect("/login")} element={<AuthLayout />}>
                {ProtectedRoute.map(route => {
                    return <Route
                        key={route.path}
                        path={route.path}
                        caseSensitive={false}
                        loader={() => route.metadata}
                        errorElement={<ErrorBoundary />}
                        element={<Suspense fallback={<ManagePage nobanner />}>{route.component}</Suspense>}
                    />
                })}
            </Route>

            <Route path="*" caseSensitive={false} element={<ManagePage code={404} />} />
        </Route>
        <Route path="admin" loader={async ({ request }) => {
            let token = localStorage.getItem('jobman-token')
            let admin = localStorage.getItem('jobman-admin')
            if (token && admin) {
                let headers = {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Authorization': token
                }

                const res = await fetch(`${API_URL}${GET_ADMIN_USER}`, { headers: headers, signal: request.signal });
                const user = await res.json();
                loaderASec();
                return user.records ?? user;
            }
            setTimeout(() => loaderASec(), 100);
            return null;
        }} element={<AdminLayout />}>
            {AdminRoute.map(route => {
                return <Route
                    key={route.path}
                    path={route.path}
                    caseSensitive={false}
                    loader={() => {
                        if (route.metadata.auth && !localStorage.getItem('jobman-admin')) {
                            if(localStorage.getItem('jobman-token')){
                                return redirect("/my-account")
                            }else{
                                return redirect("/admin/login")
                            }
                        }
                        return route.metadata
                    }}
                    errorElement={<ErrorBoundary />}
                    element={<Suspense fallback={<ManagePage nobanner />}>{route.component}</Suspense>}
                />
            })}
            <Route path="*" caseSensitive={false} element={<ManagePage code={404} />} />
        </Route>
    </>)
);