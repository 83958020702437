import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import Banner from './Banner';
import { useNavigate } from 'react-router-dom';

function ManagePage({ code, nobanner }) {
    const navigate = useNavigate()

    return (<>
        <style jsx="true">{`h1 i{ font-size: calc(  5vw + 100px); color: #0d799d}`}</style>
        {!nobanner && <Banner title={code ?? "Please Wait..."} url="/assets/images/page-banner.jpg" />}
        {code ? <>
            <div className='margin-top'>
                {code === 200 && <WorkInProgress />}
                {code === 503 && <AccessDenied />}
                {code === 404 && <NotFound />}
                <div className='text-center my-4'>
                    <button type="button" onClick={(e) => navigate(-1)} className="btn btn-primary">
                        Go Back
                    </button>
                </div>
            </div>
        </> : <PleaseWait />}
    </>)
}

export default ManagePage;

const PleaseWait = () => {

    return (<>
        <SkeletonTheme baseColor="#dee2e630">
            <style jsx="true">{`body {overflow:hidden;}`}</style>
            <Skeleton height={"100vh"} />
        </SkeletonTheme>
    </>)
}

const NotFound = () => {

    return (<>
        <section id="error" className="padding">
            <div className="container error_content">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h1><i className="bi bi-emoji-angry text-danger"></i></h1>
                        <h2 className="uppercase bottom30">Opps Page Not Found</h2>
                        <h1 className="uppercase content_space">Nothing to see here!</h1>
                    </div>
                </div>
            </div>
        </section>
    </>)
}

const AccessDenied = () => {

    return (<>
        <section id="error" className="padding">
            <div className="container error_content">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h1><i className="bi bi-emoji-frown text-secondary"></i></h1>
                        <h2 className="uppercase bottom30">Access Denied</h2>
                        <h1 className="uppercase content_space">Nothing to see here!</h1>
                    </div>
                </div>
            </div>
        </section>
    </>)
}

const WorkInProgress = () => {

    return (<>
        <section id="error" className="padding">
            <div className="container error_content">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h1><i className="bi bi-emoji-smile-fill"></i></h1>
                        <h2 className="uppercase bottom30">Work in progress...</h2>
                        <h1 className="uppercase content_space">Nothing to see here!</h1>
                    </div>
                </div>
            </div>
        </section>
    </>)
}