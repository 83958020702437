export const Loader1 = (props) => {
    return (
        <>
            <style jsx="true">{`
            .postloader{
                position: fixed;
                z-index: 7777;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
            .loader1 {
                width: 48px;
                height: 48px;
                display: inline-block;
                position: absolute;
                top: ${props.auth ? "auto" : "50%"};
            }
            .loader1::after,
            .loader1::before {
                content: '';  
                box-sizing: border-box;
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background: #0d799d;
                position: absolute;
                opacity: 0;
                left: 0;
                top: 0;
                animation: animloader1 2s linear infinite;
            }
            .loader1::after {
                animation-delay: 1s;
            }
            
            @keyframes animloader1 {
                0% {
                transform: scale(0);
                opacity: 1;
                }
                100% {
                transform: scale(1);
                opacity: 0;
                }
            }
            #loading-data-section.loader{
                opacity: .1;
                pointer-events: none;
            }
      `}</style>
            <span className="loader1"></span>
        </>
    )
}


export const authloader = (active) => {

    if (active) {
        document.getElementById("loading-data-loader").classList.remove("d-none");
        document.getElementById("loading-data-section").classList.add("loader");
    } else {
        setTimeout(() => {
            document.getElementById("loading-data-loader").classList.add("d-none");
            document.getElementById("loading-data-section").classList.remove("loader");
        }, 600);
    }
}

export const PreLoader = () => {

    return (
        <div id="preloader" className="">
            <div id="loading-animation" className="loader-3">
                <div className="loader-image">
                    <img src="/assets/images/logo.png" alt="Joinery By Design" />
                    <div className="loader-box">
                        <div className="loader-circle"></div>
                        <div className="loader-line-mask">
                            <div className="loader-line"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}