import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom'
import reportWebVitals from './reportWebVitals';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-loading-skeleton/dist/skeleton.css'
import 'swiper/swiper-bundle.css';
import './css/main.css'
import { router } from './components/Router';
import { PreLoader } from './components/Loader';
import { Provider } from 'react-redux';
import { store } from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <PreLoader />
    <RouterProvider router={router} />
    <div className="position-fixed top-0 end-0 p-2 toast-end pointer-none" style={{ 'zIndex': '1111' }} id="toastContainer">
    </div>
    </Provider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
