import { lazy } from 'react';






const MetaData = {
    login: { title: "Admin Login", description: "", banner_img: null },
    forgot_password: { title: "Admin Forgot Password", description: "", banner_img: null },
    reset_password: { title: "Admin Reset Password", description: "", banner_img: null },
    dashboard: { title: "Admin Dashboard", description: "", banner_img: null, auth: true },
    change_password: { title: "Change Password", description: "", banner_img: null, auth: true },
    leads: { title: "Leads", description: "", banner_img: null, auth: true },
    leads_view: { title: "Admin Leads View", description: "", banner_img: null, auth: true },
    admin_jobs: { title: "Admin Jobs", description: "", banner_img: null, auth: true },
    admin_quote: { title: "Admin Quote", description: "", banner_img: null, auth: true },
    admin_contact_list: { title: "Admin Contact List", description: "", banner_img: null, auth: true },
    admin_insaurence: { title: "Admin Insurance", description: "", banner_img: null, auth: true },
    admin_job_task_links: { title: "Job Task Links", description: "", banner_img: null, auth: true },
    admin_lead_task_links: { title: "Lead Task Links", description: "", banner_img: null, auth: true }
}

    const Login = lazy(() => import('../modules/Auth/Login'));
    const ForgotPassword = lazy(() => import('../modules/Auth/ForgotPassword'));
    const ResetPassword = lazy(() => import('../modules/Auth/ResetPassword'));
    const AdminInsaurence = lazy(() => import('../modules/Admin/AdminInsaurence'));
    const Dashboard = lazy(() => import('../modules/Admin/Dashboard'));
    const ChangePassword = lazy(() => import('../modules/Auth/ChangePassword'));
    const Leads = lazy(() => import('../modules/Admin/AdminLeadList'));
    const AdminLeadView = lazy(() => import('../modules/Admin/AdminLeadView'));
    const AdminJobs = lazy(() => import('../modules/Admin/AdminJobs'));
    const AdminQuote = lazy(() => import('../modules/Admin/AdminQuote'));
    const AdminContactList = lazy(() => import('../modules/Admin/ContactList'));
    const AdminTaskLinks = lazy(() => import('../modules/Admin/AdminTaskLinks'));



export const AdminRoute = [
    { path: "login", component: <Login />, metadata: MetaData.login }, 
    { path: "forgot-password", component: <ForgotPassword />, metadata: MetaData.forgot_password },
    { path: "reset-password", component: <ResetPassword />, metadata: MetaData.reset_password },
    { path: "", component: <Dashboard />, metadata: MetaData.dashboard },
    { path: "dashboard", component: <Dashboard />, metadata: MetaData.dashboard },
    { path: "change-password", component: <ChangePassword />, metadata: MetaData.change_password },
    { path: "leads", component: <Leads />, metadata: MetaData.leads },
    { path: "lead-view", component: <AdminLeadView />, metadata: MetaData.leads_view },
    { path: "admin-jobs", component: <AdminJobs />, metadata: MetaData.admin_jobs },
    { path: "admin-quote", component: <AdminQuote />, metadata: MetaData.admin_quote },
    { path: "contact", component: <AdminContactList />, metadata: MetaData.admin_contact_list },
    { path: "insaurence", component: <AdminInsaurence />, metadata: MetaData.admin_insaurence },
    { path: "job-task-links", component: <AdminTaskLinks />, metadata: MetaData.admin_job_task_links},
    { path: "lead-task-links", component: <AdminTaskLinks />, metadata: MetaData.admin_lead_task_links}
];