import { lazy } from 'react';

const MetaData = {
    home: { title: "Home", description: "", banner_img: null, banner: true },
    about_us: { title: "About Us", description: "", banner_img: null },
    services: { title: "Services", description: "", banner_img: null },
    projects: { title: "Projects", description: "", banner_img: null },
    steel_by_design: { title: "Steel By Design", description: "", banner_img: null },
    request_a_quote: { title: "Request A Quote", description: "", banner_img: null, auth: true },
    login: { title: "Login", description: "", banner_img: null },
    verify_otp: { title: "Verify Otp", description: "", banner_img: null },
    register: { title: "Register", description: "", banner_img: null },
    forgot_password: { title: "Forgot Password", description: "", banner_img: null },
    reset_password: { title: "Reset Password", description: "", banner_img: null },
    table: { title: "Table", description: "", banner_img: null },
};

const Home = lazy(() => import('../modules/FrontEnd/Home'));
const About = lazy(() => import('../modules/FrontEnd/About'));
const Login = lazy(() => import('../modules/Auth/Login'));
const Register = lazy(() => import('../modules/Auth/Register'));
const Services = lazy(() => import('../modules/FrontEnd/Services'));
const Projects = lazy(() => import('../modules/FrontEnd/Projects'));
const SteelByDesign = lazy(() => import('../modules/FrontEnd/SteelByDesign'));
const Lead = lazy(() => import('../modules/FrontEnd/Lead'));
const ForgotPassword = lazy(() => import('../modules/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('../modules/Auth/ResetPassword'));
const VerifyOtp = lazy(() => import('../modules/Auth/VerifyOtp'));
const Table = lazy(() => import('../modules/Test/Table'));

export const NormalRoute = [
    { path: "", component: <Home />, metadata: MetaData.home },
    { path: "home", component: <Home />, metadata: MetaData.home },
    { path: "about-us", component: <About />, metadata: MetaData.about_us },
    { path: "services", component: <Services />, metadata: MetaData.services },
    { path: "projects", component: <Projects />, metadata: MetaData.projects },
    { path: "steel-by-design", component: <SteelByDesign />, metadata: MetaData.steel_by_design },
    { path: "request-a-quote", component: <Lead />, metadata: MetaData.request_a_quote },
    { path: "login", component: <Login />, metadata: MetaData.login },
    { path: "verify-otp", component: <VerifyOtp />, metadata: MetaData.verify_otp },
    { path: "register", component: <Register />, metadata: MetaData.register },
    { path: "forgot-password", component: <ForgotPassword />, metadata: MetaData.forgot_password },
    { path: "reset-password", component: <ResetPassword />, metadata: MetaData.reset_password },
    { path: "table", component: <Table />, metadata: MetaData.table },
];